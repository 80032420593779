<template>
  <div class="app-page">
    <div class="header d-flex flex-row">
      <div class="left d-flex flex-column justify-center">
        <h2>Bloemenmaker</h2>
        <h3>App</h3>
        <p>Gemakkelijk bloemen bestellen met extra voordelen</p>

        <div class="btn-container d-flex flex-row align-baseline">
          <img
            src="@/assets/icons/Others/google-play.png"
            alt="mobile-app"
            class="pointer"
          />
          <img
            src="@/assets/icons/Others/apple-store.png"
            alt="mobile-app"
            class="pointer"
          />
        </div>

        <div class="scroll">
          <img
            src="@/assets/images/AppPage/scroll.png"
            alt="scroll"
            class="scroll-arrow"
          />
        </div>
      </div>
      <div class="right d-flex flex-row align-center">
        <img src="@/assets/images/AppPage/main-flower.png" alt="main-flower" />
      </div>
    </div>

    <div class="our-app d-flex flex-row align-center space-around">

      <img src="@/assets/icons/Others/our-app.png" alt="our-app.png" class="our-app-icon">
      <div class="left">
        <img
          src="@/assets/images/AppPage/personalize.png"
          alt="personalize.png"
          class="woman"
        />
        <img
          src="@/assets/images/AppPage/personalize1.png"
          alt="personalize1.png"
          class="pop-up1"
        />
        <img
          src="@/assets/images/AppPage/personalize2.png"
          alt="personalize2.png"
          class="pop-up2"
        />
        <img
          src="@/assets/images/AppPage/personalize3.png"
          alt="personalize3.png"
          class="pop-up3"
        />
      </div>
      <div class="right">
        <h5>OUR APP</h5>

        <h3>Personalize your bouquets</h3>
        <p>
          Bouquet is not just a number of flowers putted together. Turn
          recieving a bouquet from you into special occasion. Say everything you
          want in video or add a gift card to make the recepient feel like you
          are near.
        </p>
      </div>
    </div>
    <div class="explore-app">
      <h3>Explore our app’s unique features</h3>
      <p>We deliver quality. We deliver smiles.</p>
      <div class="play-btn">
        <Button :width="250" :backGround="'#000'" :color="'#fff'"
          >Watch video
          <img src="@/assets/images/AppPage/play-video.png" alt="play"
        /></Button>
      </div>
      <div class="btn-container d-flex flex-row align-baseline justify-center">
        <img
          src="@/assets/icons/Others/google-play.png"
          alt="mobile-app"
          class="pointer"
        />
        <img
          src="@/assets/icons/Others/apple-store.png"
          alt="mobile-app"
          class="pointer"
        />
      </div>
    </div>

    <div class="opportunities-wrapper">
      <Opportunities>
        <div class="order-list">
          <ol>
            <li>
              <p>Add new delivery addresses or select from your address book</p>
            </li>
            <li><p>Select flowers for each delivery destination</p></li>
            <li>
              <p>
                Take a look at your order summary, add personalized greetings if
                needed and send!
              </p>
            </li>
            <li>
              <p>Delivery throughout the Netherlands, 7 days per week.</p>
            </li>
          </ol>
        </div>
      </Opportunities>
    </div>

    <Loyalty />

    <div class="download d-flex flex-column align-center justify-center">
      <img
        src="@/assets/images/AppPage/flower.png"
        alt="flower"
        class="top-flower"
        id="flower-fall-down"
      />
      <h3>Our app is available for free on AppStore and Google Play</h3>
      <div class="btn-container d-flex flex-row align-baseline justify-center">
        <img
          src="@/assets/icons/Others/google-play.png"
          alt="mobile-app"
          class="pointer"
        />
        <img
          src="@/assets/icons/Others/apple-store.png"
          alt="mobile-app"
          class="pointer"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button";
import Opportunities from "@/components/Opportunities";
import Loyalty from "@/components/Loyalty";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "AppPage",
  components: {
    Button,
    Opportunities,
    Loyalty,
  },

  mounted() {
    const fallDown = document.querySelector("#flower-fall-down");

    gsap.to(fallDown, {
      scrollTrigger: {
        trigger: ".download",
        toggleActions: "restart none none none",
      },
      duration: 2.5,
      delay: 0.5,
      y: 500,
      ease: "sine.inOut",
    });
  },
};
</script>

